/* All pages */

/* Scroll bar settings */

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #48a1b2;
  border-radius: 0.5em;
  box-shadow: inset 2px 2px 2px hsla(0, 0%, 100%, 0.25),
    inset -2px -2px 2px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-track {
  background-color: #ffe161;
}

/* Firefox */
* {
  scrollbar-color: #48a1b2 rgba(0, 0, 0, 0.25);
}

/* scroll bar settings end */

body {
  margin: 0;
  font-family: Arial, Helvetica;
}

h1 {
  width: 100%;
  position: relative;
  top: 155px;
  background-color: #273359;
  color: white;
  margin-block-start: 0;
  margin-block-end: 40px;
}

h3 {
  border-bottom: #273359 solid 3px;
  width: 100%;
  margin-block-end: 0;
}

h4 {
  border: #273359 solid 1px;
  border-radius: 8px;
  position: relative;
  top: 140px;
  padding: 10px;
  width: 70%;
}

h4:nth-child(2) {
  background: #ffe161;
}

p {
  display: block;
  align-items: center;
  margin-block-end: 0;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  overflow-wrap: break-word;
}

blockquote {
  background-color: #f7f8ff;
  border-radius: 8px;
  padding: 1px;
  margin-block-end: 1rem;
  margin-block-start: 1rem;
}

svg {
  visibility: hidden;
  position: absolute;
  right: 1em;
  top: 2em;
}

li {
  list-style-type: none;
  margin-bottom: 5px;
  margin-left: 0;
}

.blockquoteNoBg {
  background-color: inherit;
}

.App {
  text-align: center;
  background-color: white;
  min-height: 120vh;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #273359;
}

.emailAdd {
  color: inherit;
  font-weight: bold;
}

.picContainer {
  margin: 0 auto;
  width: 80%;
  text-align: center;
  padding-top: 10px;
}

.aboutPic {
  border-radius: 8px;
  width: 260px;
}

.feedbackPic {
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
  width: 260px;
}

/* Navbar */

.logo {
  position: relative;
}

.navBar {
  color: white;
  text-align: center;
  font-size: 30px;
  text-decoration: none;
  background-color: #273359;
  display: inline-flex;
  position: relative;
  vertical-align: middle;
  top: 22px;
  margin: 1em;
}

.navBarLogo {
  display: inline-flex;
  position: absolute;
  left: 10px;
  height: 150px;
}

nav {
  background-color: #273359;
  position: fixed;
  top: 0;
  height: 155px;
  width: 100vw;
  z-index: 1;
}

.navDiv {
  background-color: #273359;
  position: fixed;
  padding-top: 20px;
  width: 100%;
  z-index: 1;
}

.navBarRight {
  position: absolute;
  right: 1em;
}

.navBarDown {
  position: absolute;
  top: 119px;
  width: 100vw;
  background-color: #273359;
  height: 100vh;
}

.fullList {
  height: 100%;
  vertical-align: middle;
}

/* Footer */

.footerDiv {
  background-color: #273359;
  width: 100%;
  color: white;
  text-align: right;
  display: inline-flex;
  position: relative;
  min-height: 221px;
  bottom: -5px;
}

.footerP {
  margin: 5px 20px;
  display: inline-table;
  position: absolute;
  right: 0;
  bottom: 0;
}

/* Homepage */

.homeH1 {
  top: 146px;
}

.homeLogoDiv {
  width: 100%;
  background-color: #273359;
  position: relative;
  top: 106px;
}

.homeLogo {
  background-color: #273359;
}

.pHome {
  padding: 0 1em;
}

.picText {
  display: inline-flex;
  position: initial;
  width: 80%;
  margin-bottom: 20px;
  margin-top: 100px;
  text-align: left;
}

.homePic {
  width: 260px;
  height: 240px;
  border-radius: 8px;
}

.section2 {
  background-color: #273359;
  color: white;
  width: 100%;
  min-height: 400px;
  border-top: 1em solid #273359;
}

.section3 {
  color: #273359;
  width: 100%;
  min-height: 400px;
}

.section4 {
  background-color: #ffe161;
  color: #273359;
  width: 100%;
  min-height: 400px;
}

.section1 {
  background-color: #e6f4f1;
  color: #273359;
  width: 100%;
  min-height: 400px;
  padding-top: 1em;
}

/* Latest news */

.article {
  text-align: left;
  width: 100%;
}

.article1 {
  text-align: left;
  width: 100%;
  margin-top: 8em;
}

.articleContainer {
  width: 80%;
  padding-bottom: 1em;
  margin: 0 auto;
}

.articleMore {
  border: none;
  background: none;
  display: contents;
  font-family: inherit;
  font-size: inherit;
  font-style: italic;
  cursor: pointer;
}

.latestNewsIntro {
  position: relative;
  top: 140px;
  width: 80%;
}

.latestNewsPic1 {
  width: 50%;
  border-radius: 8px;
  margin: 5px;
}

.latestNewsPic3,
.latestNewsPic6 {
  width: 260px;
  height: 240px;
  border-radius: 8px;
  margin: 5px;
}

/* Links page */

.linksDiv {
  min-height: 100%;
  margin-top: 150px;
  text-align: left;
  position: relative;
  left: -25%;
}

/* Menu page */

.detailsP {
  position: relative;
  top: 130px;
}

table {
  margin: 0 auto;
}

td {
  width: 14rem;
  text-align: left;
}

.menuContainer {
  margin-top: 8em;
  width: 80%;
  padding-bottom: 1em;
}

.menuTable {
  margin: 20px auto;
  width: 50%;
}

.menuPic {
  position: relative;
  top: 130px;
  border-radius: 8px;
  width: 260px;
}

.menuPic:last-of-type {
  margin-top: 20px;
}

.tdPrice {
  text-align: right;
}

.menuLabelTd {
  padding-bottom: 1px;
}

/* 404 */

.pic404 {
  width: 50%;
  margin: 6em;
}

/* Customer feedback */

.pCF {
  position: relative;
  top: 6em;
  width: 80%;
}

.comingSoon {
  position: relative;
  top: 200px;
}

/* Modal */

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #e6f4f1;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 8px;
  /* background-image: url("./images/xmas-border.jpg");
  background-repeat: no-repeat;
  background-size: contain; */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Optimisation for small screens */

@media (max-width: 980px) {
  .fullList {
    display: none;
  }
  .homeNav {
    height: 6em;
  }

  .navBar {
    display: block;
    background-color: #273359;
  }
  .picText {
    display: block;
    margin: 20px auto;
    text-align: center;
  }
  .homePic {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  #page-body > div > header > div:nth-child(2) > div > p {
    margin-block-start: 6em;
  }
  p {
    margin-block-end: 1em;
  }
  .section1a {
    border-top: 6em solid #273359;
  }
  .section2 .section3 .section4 {
    margin-top: 80px;
  }
  .latestNewsPic1 {
    width: 80%;
  }
  .latestNewsPic3,
  .latestNewsPic6 {
    width: auto;
    height: auto;
    object-fit: contain;
    max-width: 80%;
    max-height: 100%;
    border-radius: 8px;
  }
  .linksDiv {
    width: 80%;
    padding-left: 38%;
    margin-top: 110px;
  }
  .pMobile {
    margin-block-start: 1em;
    width: 80%;
    margin: 15px auto;
  }
  .menuContainer {
    width: 100%;
  }
  h3 {
    width: 80%;
  }
  .h3Menu {
    margin: 0 auto;
  }
  .navBarLogo {
    height: 8em;
    position: initial;
  }
  .homeLogo {
    width: 100vw;
    position: relative;
    top: 2em;
  }
  .homeLogoDiv {
    top: 59px;
  }
  .homeH1 {
    top: 60px;
    padding-top: 2em;
    margin-bottom: 0;
  }
  svg {
    visibility: visible;
  }
  .pHome {
    padding: 2em 1em;
  }
  .pModal {
    margin-block-start: 2em !important;
    width: 80%;
    margin: 5px auto;
  }
  .pCF {
    top: 7em;
  }
  #cap_gold {
    right: 35vw !important;
  }
}

/* Award */

@font-face {
  font-family: "nexa-blackitalic";
  font-weight: 800;
  src: url(https://awards.infcdn.net/fonts/nexa-blackitalic.ttf);
}

@font-face {
  font-family: "RuslanDisplay";
  font-weight: 900;
  src: url(https://awards.infcdn.net/fonts/RuslanDisplay.ttf);
}

#cap_gold {
  position: absolute;
  width: 253px;
  height: 218px;
  right: 15vw;
  display: table-cell;
  background: url("https://awards.infcdn.net/img/circle_gold_gold.svg")
    no-repeat center center;
  background-size: contain;
  flex-shrink: 0 !important;
  align-self: center;
  overflow: hidden;
  cursor: pointer;
}
#cap_gold.cap_gold_black {
  background: url("https://awards.infcdn.net/img/circle_gold_black.svg")
    no-repeat center center;
  background-size: contain;
}
#cap_gold .cap_gold_nom {
  font-family: "nexa-blackitalic", sans-serif;
  font-size: 12px !important;
  line-height: 100% !important;
  position: absolute;
  left: 27px;
  top: 22px;
  text-transform: uppercase;
  font-weight: 800;
  color: #000000 !important;
  transform: rotate(-15deg);
}
#cap_gold .cap_gold_year {
  font-family: "RuslanDisplay", sans-serif !important;
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  color: #ffffff !important;
  transform: rotate(-15deg);
  left: 93px;
  top: 80px;
  position: absolute;
  margin: 0;
  padding: 0;
}
#cap_gold .cap_gold_name {
  width: 100%;
  height: 100%;
  position: absolute;
  left: -203px;
  top: 141px;
  transform: rotate(-15deg);
  display: block !important;
  padding: 0;
}

#cap_gold .cap_gold_name svg {
  max-width: 700px;
  visibility: visible;
  position: relative;
  right: 0;
  top: 0;
}

#cap_gold .cap_gold_nom svg {
  max-width: 700px;
  visibility: visible;
  position: relative;
  right: 0;
  top: 0;
}

#cap_gold .cap_gold_name_txt {
  font-family: "RuslanDisplay", sans-serif;
  font-weight: 900;
  font-size: 22px !important;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: white;
}

#cap_gold .cap_gold_name.cap_gold-two_str.str1 {
  top: 132px;
}
#cap_gold .cap_gold_name.cap_gold-two_str.str2 {
  top: 146px;
}

#cap_gold .cap_gold_name_txt.f20 {
  font-size: 20px;
}
#cap_gold .cap_gold_name_txt.f18 {
  font-size: 18px;
}
#cap_gold .cap_gold_name_txt.f16 {
  font-size: 16px;
}
#cap_gold .cap_gold_name_txt.f14 {
  font-size: 14px;
}
#cap_gold .cap_gold_name_txt.f13 {
  font-size: 13px;
}
#cap_gold .cap_gold_name_txt.f12 {
  font-size: 12px;
}
#cap_gold .cap_gold_name_txt.f11 {
  font-size: 11px;
}
#cap_gold .cap_gold_name_txt.f10 {
  font-size: 10px;
}
#cap_gold .cap_gold_name_txt.f9 {
  font-size: 9px;
}
#cap_gold .cap_gold_name_txt.f8 {
  font-size: 8px;
}

#cap_gold .cap_gold_nom_txt {
  text-transform: uppercase;
}
#cap_gold .cap_gold_nom_txt.f11 {
  font-size: 11px;
}
#cap_gold .cap_gold_nom_txt.f10 {
  font-size: 10px;
}
#cap_gold .cap_gold_nom_txt.f9 {
  font-size: 9px;
}
#cap_gold .cap_gold_nom_txt.f8 {
  font-size: 8px;
}
#cap_gold .cap_gold_nom_txt.f7 {
  font-size: 7px;
}
#cap_gold .cap_gold_nom_txt.f6 {
  font-size: 6px;
}
